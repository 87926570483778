<template>
	<div class="relative flex h-8 w-8 cursor-pointer justify-center items-center group z-50" :class="buttonStyling" @click="emit('click')">
		<FontAwesomeIcon v-if="icon" :icon="icon" :class="iconStyling" />
		<slot></slot>
		<div
			v-if="label || slots.label"
			class="select-none absolute top-1/2 hidden px-1.5 py-0.5 bg-black whitespace-nowrap bg-opacity-80 text-white text-xs -translate-y-1/2 group-hover:inline-block z-100 transform"
			:class="{
				'-right-1.5': labelRight,
				'-left-1.5': !labelRight,
				'translate-x-full': labelRight, // this need to go last!
				'-translate-x-full': !labelRight, // this need to go last!
			}"
		>
			{{ label }}<slot name="label"></slot>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { computed, CSSProperties, defineProps, withDefaults } from 'vue';

	interface Props {
		classes?: CSSProperties;
		icon?: IconDefinition;
		iconClasses?: CSSProperties;
		label?: string;
		labelRight?: boolean;
		disabled?: boolean;
		selected?: boolean;
	}

	import { useSlots } from 'vue';
	const slots = useSlots();

	const props = withDefaults(defineProps<Props>(), {
		disabled: false,
		selected: false,
		labelRight: false,
	});

	const buttonStyling = computed<CSSProperties>(() => {
		if (props.disabled) {
			return {
				...(props.classes ?? {}),
				...{
					'cursor-not-allowed': true,
					'bg-gray-100': true,
				},
			};
		} else if (props.selected) {
			return {
				...(props.classes ?? {}),
				...{
					'text-white': true,
					'bg-primary': true,
				},
			};
		}
		return {
			...(props.classes ?? {}),
			...{
				'hover:bg-primary-100': true,
			},
		};
	});

	const iconStyling = computed<CSSProperties>(() => {
		if (props.disabled) {
			return {
				...(props.iconClasses ?? {}),
				...{
					'opacity-20': true,
				},
			};
		}
		return props.classes ?? {};
	});

	const emit = defineEmits<{
		(e: 'click'): void;
	}>();
</script>
